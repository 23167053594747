export function secondsToHHMM(seconds) {
  if(!seconds) return '00:00';
  let hours = Math.floor(+seconds / 3600);
  let minutes = Math.floor((+seconds % 3600) / 60);
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
}

export function floorSeconds(seconds) {
  const floorSecs =(Math.floor(+seconds/60)*60);
  return floorSecs;
}

export function secondsToHours(seconds) {
  const hours = (seconds / 3600).toFixed(2);
  return hours;
}


export function toTitleCase(name){
  if (!name || !name.length) return true;
  const newName = name.toLowerCase();
  return newName[0].toUpperCase() +  newName.slice(1);
}

export function exportFile(data, filename){
  if (window.navigator.msSaveOrOpenBlob) {
    const a = document.createElement("download");
    document.body.appendChild(a);
    a.style.display = "none";
    a.onclick = ((evx) => 
    {
      const  myBlob =  new Blob([data.action.payload] ,
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      window.navigator.msSaveOrOpenBlob(myBlob, filename);
    });
    a.click();
  }
  // chrome ,safari and mozilla browsers 
  else {
    const  myBlob =  new Blob([data.action.payload] ,
      {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    const  url = window.URL.createObjectURL(myBlob);
    const element = document.createElement("a");
    document.body.appendChild(element);
    element.href = url;
    element.download = filename;
    element.click();
  }
}


export function exportTextFile(data, filename) {
  const textData = new TextDecoder().decode(data.action.payload);
  const correctedText = textData.replace(/\\n/g, '\n').replace(/"/g, '');

  const myBlob = new Blob([correctedText], { type: "text/plain" });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(myBlob, filename);
  } else {
    const url = window.URL.createObjectURL(myBlob);
    const element = document.createElement("a");
    document.body.appendChild(element);
    element.href = url;
    element.download = filename;
    element.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(element);
  }
}



export function   convertToBase64(file){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
    resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
    reader.onerror = (error) => reject(error);
  });
}

export default {
  secondsToHHMM,
  secondsToHours,
  floorSeconds,
  toTitleCase,
  exportFile,
  exportTextFile,
  convertToBase64,
};
